import { PasswordlessAuth } from "@localkitchens/passwordless-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { initDefaultOpsApiClient } from "opsapi/api";
import { useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { initDefaultShopApiClient } from "shopapi/api";
import App from "./App";
import { ApolloWrapper } from "./app/ApolloWrapper";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./tailwind.output.css";

const authOrigin = process.env.REACT_APP_AUTH_ORIGIN || "";
const opsBackendUrl = process.env.REACT_APP_AUTH_ORIGIN || "";
const googleClientId = process.env.REACT_APP_GOOGLE_OATH_CLIENT_ID || "";
const shopBackendUrl = process.env.REACT_APP_SHOP_BACKEND_URL || "";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

initDefaultShopApiClient({ shopBackendUrl });
initDefaultOpsApiClient({ opsBackendUrl });

const { identify } = window.analytics;

type TrackingEvent = {
  event: string;
  properties?: object;
};

type TrackState = {
  track: TrackingHandler;
};
type TrackingHandler = (trackingEvent: TrackingEvent) => void;

const track = () => {};

const AppWrapper = () => {
  // Enables receiving a tracking handler from a child component. Tracking
  // requires data from graphql which will not be populated at
  // the time of rendering this component.
  const [tracker, setTrackingHandler] = useState<TrackState>({ track });

  const trackingHandler = useMemo(() => tracker.track, [tracker]);

  useEffect(() => {
    if (
      !window.location.href.includes(".com") &&
      !window.location.href.includes(".kitchen") &&
      !window.location.href.includes(".dev")
    ) {
      const url = window.location.href.replace(".co", ".com");
      window.location.href = url;
    }
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <PasswordlessAuth
          authOrigin={authOrigin}
          googleClientId={googleClientId}
          track={trackingHandler}
          identify={identify}
        >
          <ApolloWrapper>
            <App setTrackingHandler={setTrackingHandler} />
          </ApolloWrapper>
        </PasswordlessAuth>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const rootContainer = document.getElementById("root");
if (!rootContainer) {
  throw new Error("Root container not found");
}
const root = createRoot(rootContainer);
root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type { TrackingEvent, TrackingHandler, TrackState };
