import { useEffect, useState } from "react";
import { BrandV2 } from "../../graphql/types";
import useBrands from "./hooks/useBrands";
import useLocations from "./hooks/useLocations";
import { TYPE_OPTIONS } from "./lib/constants";
import { TargetType } from "./lib/types";

interface MenuEditorFilterProps {
  selectedLocation?: string;
  onSelectLocation: (selectedLocation?: string) => void;
  selectedBrand?: number;
  onSelectBrand: (selectedBrand?: number) => void;
  selectedType?: TargetType;
  onSelectType: (selectedType?: TargetType) => void;
  itemName?: string;
  onItemNameChange: (selectedItemName?: string) => void;
  onResetFilters: () => void;
}

export const MenuEditorFilters = ({
  selectedLocation,
  onSelectLocation,
  selectedBrand,
  onSelectBrand,
  selectedType,
  onSelectType,
  itemName,
  onItemNameChange,
  onResetFilters,
}: MenuEditorFilterProps) => {
  const { data: brands } = useBrands(selectedLocation);
  const [filteredBrands, setFilteredBrands] = useState<BrandV2[]>();

  useEffect(() => {
    const sortedBrands = [...brands].sort((a, b) =>
      (a?.name || "").localeCompare(b?.name || ""),
    );
    setFilteredBrands(sortedBrands);
  }, [brands]);

  const { data: locations } = useLocations(undefined);

  return (
    <div className="space-y-8 divide-y divide-gray-200 ">
      <div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="id"
              className="block text-sm font-medium text-gray-700"
            >
              Location
            </label>
            <div className="mt-1">
              <select
                id="brandId"
                name="brandId"
                autoComplete="brandId"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => {
                  if (e?.target?.value == "DEFAULT_SELECTED") {
                    onSelectLocation(undefined);
                  } else {
                    onSelectLocation(e?.target?.value);
                  }
                }}
                value={selectedLocation || "DEFAULT_SELECTED"}
              >
                <option key={`location-default`} value={"DEFAULT_SELECTED"}>
                  --
                </option>
                {locations?.map((location, index: number) => {
                  return (
                    <option key={`brand-${index}`} value={location?.slug}>
                      {location?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="id"
              className="block text-sm font-medium text-gray-700"
            >
              Brand
            </label>
            <div className="mt-1">
              <select
                id="brandId"
                name="brandId"
                autoComplete="brandId"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => {
                  if (e?.target?.value == "DEFAULT_SELECTED") {
                    onSelectBrand(undefined);
                  } else {
                    onSelectBrand(parseInt(e?.target?.value));
                  }
                }}
                value={selectedBrand || "DEFAULT_SELECTED"}
              >
                <option key={`location-default`} value={"DEFAULT_SELECTED"}>
                  --
                </option>
                {filteredBrands?.map((brand, index: number) => {
                  return (
                    <option key={`brand-${index}`} value={brand.id}>
                      {brand.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="id"
              className="block text-sm font-medium text-gray-700"
            >
              Type
            </label>
            <div className="mt-1">
              <select
                id="targetType"
                name="targetType"
                autoComplete="targetType"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => {
                  if (e?.target?.value == "DEFAULT_SELECTED") {
                    onSelectType(undefined);
                  } else {
                    onSelectType(e?.target?.value as TargetType);
                  }
                }}
                value={selectedType || "DEFAULT_SELECTED"}
              >
                <option key={`target-type-default`} value={"DEFAULT_SELECTED"}>
                  --
                </option>
                {TYPE_OPTIONS?.map((typeName, index: number) => {
                  return (
                    <option key={`brand-${index}`} value={typeName}>
                      {typeName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="itemName"
              className="block text-sm font-medium text-gray-700"
            >
              Item Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="itemName"
                id="itemName"
                autoComplete="itemName"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={itemName}
                onChange={(e) => onItemNameChange(e?.target?.value)}
              />
            </div>
          </div>
          <div className="sm:col-span-4">
            <div className="mt-6 flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onResetFilters}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
