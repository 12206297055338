import React, { Dispatch, SetStateAction, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { CateringOrder } from "../../types";
import { Button, FormInput, FormLabel } from "../../ui/components";
import ToastContext from "../../utils/contexts/ToastContext";
import { Loading } from "../../ui/components/Loading";
import { DatePicker } from "antd";
import moment from "moment";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteCateringOrder } from "./hooks/useDeleteCateringOrder";

type CateringOrderFormProps = {
  order: any;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: any) => void;
  cateringOrder?: CateringOrder;
  loading: boolean;
};

export const CateringOrderForm = ({
  order,
  setShowModal,
  onSubmit,
  cateringOrder,
  loading,
}: CateringOrderFormProps) => {
  const { showToast } = useContext(ToastContext);
  const {
    deleteCateringOrderMutation,
    loading: deleteCateringOrderMutationLoading,
  } = useDeleteCateringOrder(() => {
    setShowModal(false);
    showToast({
      description: "Invoice deleted",
      variant: "success",
      seconds: 2,
      onClose: () => {},
    });
  });
  let orderJsonData;
  try {
    orderJsonData = JSON.parse(order.raw_orders_data);
  } catch {}
  const { control, handleSubmit, watch, setValue } = useForm();

  const watchPrice = (fieldName: string, intValue?: number): number =>
    parseFloat(watch(fieldName, intValue ? intValue / 100 : undefined) || 0);

  const watchedTotal = watchPrice("total", cateringOrder?.total);
  const watchedSubtotal = watchPrice("subtotal", cateringOrder?.subtotal);
  const watchedDiscount = watchPrice("discount", cateringOrder?.discount);
  const watchedTax = watchPrice(
    "tax",
    cateringOrder?.tax || orderJsonData?.tax_amount,
  );
  const watchedTaxRate = watchPrice(
    "tax_rate",
    cateringOrder?.tax_rate || orderJsonData?.tax_rate,
  );
  const watchedTip = watchPrice("tip", cateringOrder?.tip);
  const watchedTipRate = watchPrice("tip_rate", cateringOrder?.tip_rate);
  const watchedServiceFee = watchPrice(
    "service_fee",
    cateringOrder?.service_fee,
  );
  const watchedServiceFeeRate = watchPrice(
    "service_fee_rate",
    cateringOrder?.service_fee_rate,
  );
  const watchedCustomerDeliveryFee = watchPrice(
    "customer_delivery_fee",
    cateringOrder?.customer_delivery_fee,
  );

  const watchedCalculatedSubtotal = watchedSubtotal - watchedDiscount;
  const watchedCalculatedTax =
    watchedTaxRate == 0 && watchedTax > 0
      ? watchedTax
      : (watchedTaxRate / 100) * watchedCalculatedSubtotal;
  const watchedCalculatedTip =
    watchedTipRate == 0 && watchedTip > 0
      ? watchedTip
      : (watchedTipRate / 100) * watchedCalculatedSubtotal;

  const watchedCalculatedServiceFee =
    watchedServiceFeeRate == 0 && watchedServiceFee > 0
      ? watchedServiceFee
      : (watchedServiceFeeRate / 100) * watchedCalculatedSubtotal;
  const watchedCalculatedTotal = parseFloat(
    (
      watchedCalculatedSubtotal +
      watchedCalculatedTax +
      watchedCustomerDeliveryFee +
      watchedCalculatedTip +
      watchedCalculatedServiceFee
    ).toFixed(2),
  );

  const preparePayload = (data: any) => {
    return {
      invoice_id: data?.invoice_id || null,
      external_id: cateringOrder ? undefined : order?.external_id.toString(),
      invoice_paid_at: data?.invoice_paid_at,
      tax: data?.tax ? Math.round(parseFloat(data?.tax) * 100) : 0,
      tax_rate: data?.tax_rate
        ? Math.round(parseFloat(data?.tax_rate) * 100)
        : 0,
      tip: data?.tip ? Math.round(parseFloat(data?.tip) * 100) : 0,
      tip_rate: data?.tip_rate
        ? Math.round(parseFloat(data?.tip_rate) * 100)
        : 0,
      discount: data?.discount
        ? Math.round(parseFloat(data?.discount) * 100)
        : 0,
      total: data?.total ? Math.round(parseFloat(data?.total) * 100) : 0,
      subtotal: data?.subtotal
        ? Math.round(parseFloat(data?.subtotal) * 100)
        : 0,
      customer_delivery_fee: data?.customer_delivery_fee
        ? Math.round(parseFloat(data?.customer_delivery_fee) * 100)
        : 0,
      service_fee: data?.service_fee
        ? Math.round(parseFloat(data?.service_fee) * 100)
        : 0,
      service_fee_rate: data?.service_fee_rate
        ? parseFloat(data?.service_fee_rate) * 100
        : 0,
    };
  };

  const onSubmitHandler = async (data: any) => {
    try {
      const payload = preparePayload(data);
      let mutationVariables = {};

      if (cateringOrder) {
        mutationVariables = {
          id: parseInt(cateringOrder.id.toString(), 10),
          input: payload,
        };
      } else {
        mutationVariables = {
          input: payload,
        };
      }

      await onSubmit({
        variables: mutationVariables,
        refetchQueries: ["OperationsCateringOrder"],
      });

      setShowModal(false);
      showToast({
        description: cateringOrder ? "Invoice saved" : "Invoice attached!",
        variant: "success",
        seconds: 2,
        onClose: () => {},
      });
    } catch (err) {
      showToast({
        description: "There was an error submitting the catering order",
        variant: "error",
        seconds: 2,
        onClose: () => {},
      });
    }
  };

  const calculateTotal = () => {
    const calculatedSubtotal = watchedSubtotal - watchedDiscount;
    const calculatedTax =
      watchedTaxRate == 0 && watchedTax > 0
        ? watchedTax
        : (watchedTaxRate / 100) * calculatedSubtotal;
    const calculatedTip =
      watchedTipRate == 0 && watchedTip > 0
        ? watchedTip
        : (watchedTipRate / 100) * calculatedSubtotal;
    const calculatedServiceFee =
      watchedServiceFeeRate == 0 && watchedServiceFee > 0
        ? watchedServiceFee
        : (watchedServiceFeeRate / 100) * calculatedSubtotal;
    const calculatedTotal =
      calculatedSubtotal +
      calculatedTax +
      watchedCustomerDeliveryFee +
      calculatedTip +
      calculatedServiceFee;
    setValue("total", calculatedTotal.toFixed(2));
    setValue("tax", calculatedTax.toFixed(2));
    setValue("tip", calculatedTip.toFixed(2));
    setValue("service_fee", calculatedServiceFee.toFixed(2));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className={"relative grid grid-cols-3 gap-6"}
    >
      {loading ? (
        <div className={"absolute h-full w-full "}>
          <Loading />
        </div>
      ) : null}
      <div className="col-span-1">
        <FormLabel title="External ID" htmlFor="external_id" />
        <Controller
          name="external_id"
          control={control}
          rules={{ required: true }}
          defaultValue={order.external_id}
          render={({ field }) => {
            return (
              <FormInput
                type="text"
                id="external_id"
                {...field}
                disabled
                className={
                  "flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300 bg-gray-50 text-gray-600 cursor-not-allowed"
                }
              />
            );
          }}
        />
      </div>
      <div>
        <FormLabel title="Invoice ID" htmlFor="invoice_id" />
        <Controller
          name="invoice_id"
          control={control}
          rules={{ required: false }}
          defaultValue={cateringOrder ? cateringOrder.invoice_id : ""}
          render={({ field }) => {
            return <FormInput type="text" id="invoice_id" {...field} />;
          }}
        />
      </div>

      <div className="">
        <FormLabel title="Invoice paid at" htmlFor="invoice_paid_at" />
        <Controller
          name="invoice_paid_at"
          control={control}
          rules={{ required: true }}
          defaultValue={moment(
            cateringOrder?.invoice_paid_at
              ? parseInt(cateringOrder?.invoice_paid_at)
              : moment("00:00:00", "HH:mm:ss"),
          )}
          render={(props) => {
            return (
              <DatePicker
                {...props.field}
                getPopupContainer={(triggerNode: any) => {
                  return triggerNode?.parentNode;
                }}
                format="MM/DD/YYYY"
                onChange={props.field.onChange}
                className={"flex-1 p-2 w-full"}
              />
            );
          }}
        />
      </div>
      <div className="">
        <FormLabel title="Subtotal" htmlFor="subtotal" />
        <div className="relative">
          <Controller
            name="subtotal"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.subtotal
                ? cateringOrder.subtotal / 100
                : 0.0
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="subtotal"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="subtotal">
              $
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel title="Service fee" htmlFor="service_fee" />
        <div className="relative">
          <Controller
            name="service_fee"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.service_fee
                ? cateringOrder.service_fee / 100
                : 0.0
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="service_fee"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="service_fee">
              $
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel title="Service fee Rate" htmlFor="service_fee_rate" />
        <div className="relative">
          <Controller
            name="service_fee_rate"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.service_fee_rate
                ? cateringOrder.service_fee_rate / 100
                : 0.0
              : orderJsonData
              ? orderJsonData.service_fee_rate / 100
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="service_fee_rate"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="service_fee_rate">
              %
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel title="Tax" htmlFor="tax" />
        <div className="relative">
          <Controller
            name="tax"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.tax
                ? cateringOrder.tax / 100
                : 0.0
              : orderJsonData
              ? orderJsonData.tax_amount / 100
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="tax"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="tax">
              $
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel title="Tax Rate" htmlFor="tax_rate" />
        <div className="relative">
          <Controller
            name="tax_rate"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.tax_rate
                ? cateringOrder.tax_rate / 100
                : 0.0
              : orderJsonData
              ? orderJsonData.tax_rate / 100
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder="0.00"
                  type="text"
                  id="tax_rate"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="tax_rate">
              %
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel title="Tip" htmlFor="tip" />
        <div className="relative">
          <Controller
            name="tip"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.tip
                ? cateringOrder.tip / 100
                : 0.0
              : orderJsonData
              ? orderJsonData.tip_amount / 100
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="tip"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="tip">
              $
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel title="Tip Rate" htmlFor="tip_rate" />
        <div className="relative">
          <Controller
            name="tip_rate"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.tip_rate
                ? cateringOrder.tip_rate / 100
                : 0.0
              : orderJsonData
              ? orderJsonData.tip_rate / 100
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder="0.00"
                  type="text"
                  id="tip_rate"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="tip_rate">
              %
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel
          title="Customer delivery fee"
          htmlFor="customer_delivery_fee"
        />
        <div className="relative">
          <Controller
            name="customer_delivery_fee"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.customer_delivery_fee
                ? cateringOrder.customer_delivery_fee / 100
                : 0.0
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder="0.00"
                  type="text"
                  id="customer_delivery_fee"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span
              className="text-gray-500 sm:text-sm"
              id="customer_delivery_fee"
            >
              $
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <FormLabel
          title="Total"
          htmlFor="total"
          information={
            <p>
              <code className="bg-red-50 text-red-600 rounded-md p-1">
                Total = (Subtotal - Discount) + Tax + Service fee + Customer
                delivery fee + Tip
              </code>
              <br />
              <i>Where </i>
              <br />
              <ul className="list-disc ml-3">
                <li>
                  <code className="bg-red-50 text-red-600 rounded-md p-1">
                    Tax = Tax rate * (Subtotal - Discount)
                  </code>
                  <i>
                    {" "}
                    if{" "}
                    <code className="bg-red-50 text-red-600 rounded-md p-1">{`Tax Rate >= 0`}</code>
                  </i>
                </li>
                <li>
                  <code className="bg-red-50 text-red-600 rounded-md p-1">
                    Service Fee = Service Fee rate * (Subtotal - Discount)
                  </code>
                  <i>
                    {" "}
                    if{" "}
                    <code className="bg-red-50 text-red-600 rounded-md p-1">{`Service Fee Rate >= 0`}</code>
                  </i>
                </li>
                <li>
                  <code className="bg-red-50 text-red-600 rounded-md p-1">
                    Tip = Tip Rate * (Subtotal - Discount)
                  </code>
                  <i>
                    {" "}
                    if{" "}
                    <code className="bg-red-50 text-red-600 rounded-md p-1">{`Tip Rate >= 0`}</code>
                  </i>
                </li>
              </ul>
            </p>
          }
        />
        <div className="relative">
          <Controller
            name="total"
            control={control}
            rules={{ required: true }}
            defaultValue={(cateringOrder
              ? cateringOrder.total
                ? cateringOrder.total / 100
                : 0.0
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="total"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="total">
              $
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <FormLabel title="Discount" htmlFor="discount" />
        <div className="relative">
          <Controller
            name="discount"
            control={control}
            defaultValue={(cateringOrder
              ? cateringOrder.discount
                ? cateringOrder.discount / 100
                : 0.0
              : 0.0
            ).toFixed(2)}
            render={({ field }) => {
              return (
                <FormInput
                  placeholder={"0.00"}
                  type="text"
                  id="discount"
                  {...field}
                />
              );
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="discount">
              $
            </span>
          </div>
        </div>
      </div>
      <div>
        <FormLabel title="Calculate total" htmlFor="calc" />
        <Button
          type="button"
          backgroundColor="bg-gray-500"
          onClick={calculateTotal}
        >
          Calculate
        </Button>
      </div>

      <div className="flex  mt-5 justify-between w-full col-span-3">
        <div className="">
          {cateringOrder && (
            <Button
              disabled={watchedCalculatedTotal !== watchedTotal}
              className={`mr-3 ${
                watchedCalculatedTotal != watchedTotal
                  ? "bg-opacity-50 cursor-not-allowed hover:bg-opacity-50"
                  : ""
              }`}
            >
              Save
            </Button>
          )}
          {!cateringOrder && (
            <Button
              disabled={watchedCalculatedTotal != watchedTotal}
              className={`mr-3 ${
                watchedCalculatedTotal != watchedTotal
                  ? "bg-opacity-50 cursor-not-allowed hover:bg-opacity-50"
                  : ""
              }`}
            >
              Create
            </Button>
          )}
          <Button
            type="button"
            backgroundColor="bg-gray-500"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
        <div>
          {cateringOrder && (
            <Button
              type="button"
              backgroundColor="bg-red-500"
              onClick={async () => {
                await deleteCateringOrderMutation({
                  variables: { id: parseInt(cateringOrder.id.toString(), 10) },
                });
              }}
              className={"flex gap-4 items-center"}
            >
              Delete
              {deleteCateringOrderMutationLoading ? (
                <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4" />
              ) : (
                <FontAwesomeIcon icon={faTrash} className="" />
              )}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};
